import React from 'react'

function ImageCard({thumbnail, titre}) {
  return (<>
    {thumbnail && <img alt={titre} src={`${process.env.REACT_APP_API_IMAGE}/image/${process.env.REACT_APP_USER}/bigthumbnail/${thumbnail}`} />}
    {!thumbnail && 

        <img alt={titre} src={`${process.env.REACT_APP_WEBSITE}/assets/img/thumbnail.webp`} />

    }
  </>)
}

export default ImageCard
