import React, { Component, useState, useEffect } from 'react';
import { Form, Input, Button, Select, Badge, Flex, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import { getStats, getAnnonces } from '../../slices/annoncesSlice';
let publicUrl = process.env.PUBLIC_URL + '/'
const { Option } = Select
const HomeBanner = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { idannonce } = useParams()
	const { stats, loading } = useSelector((state) => state.annonces);

	const [searchTitle, setSearchTitle] = useState('')
	const [searchTransaction, setSearchTransaction] = useState('')
	const [searchCategory, setSearchCategory] = useState('')
	const [searchPriceMin, setSearchPriceMin] = useState('')
	const [searchPriceMax, setSearchPriceMax] = useState('')
	const [searchPriceType, setSearchPriceType] = useState('')

	const [searchLocation, setSearchLocation] = useState('')
	const [searchWilaya, setSearchWilaya] = useState('')
	const [searchDaira, setSearchDaira] = useState('')
	const [searchCommune, setSearchCommune] = useState('')
	const [searchSuperficie, setSearchSuperficie] = useState('')
	const [searchPieces, setSearchPieces] = useState('')
	const [searchSdb, setSearchSdb] = useState('')
	const [searchEtages, setSearchEtages] = useState('')

	const [form] = Form.useForm();

	useEffect(() => {
		//INIT FIRST
		dispatch(getAnnonces({}))
		dispatch(getStats())
	}, [])

	useEffect(() => {

		if (stats) {
			console.log(stats)
		}
	}, [stats])

	useEffect(() => {
		if (stats?.wilayas.length === 1) {
			const singleWilaya = stats.wilayas[0].wilaya;
			setSearchWilaya(singleWilaya);
		}
	}, [stats?.wilayas]);

	const handleFilter = () => {
		dispatch(getAnnonces({
			searchTitle,
			searchTransaction,
			searchCategory,
			searchPriceMin,
			searchPriceMax,
			searchWilaya,
			searchDaira,
			searchCommune,
			searchLocation
		})).then(() => history.push('/recherche'))
			.catch((error) => console.error(error));
	}

	const handleShowCategory = value => {
		if (value !== 'tous') {
			setSearchCategory(value)
		} else {
			setSearchCategory('')
		}

	}
	const handleShowWilaya = value => {
		if (value !== 'tous') {
			setSearchWilaya(value)
		} else {
			setSearchWilaya('')
		}

	}
	const handleShowDaira = value => {
		if (value !== 'tous') {
			setSearchDaira(value)
		} else {
			setSearchDaira('')
		}
	}

	const handleShowCommune = value => {
		if (value !== 'tous') {
			setSearchCommune(value)
		} else {
			setSearchCommune('')
		}
	}

	const handleShowTransactions = value => {

		if (value !== 'tous') {
			setSearchTransaction(value)
		} else {
			setSearchTransaction('');
		}

	}
	const handleReset = () => {
		form.resetFields();
		setSearchTitle('');
		setSearchTransaction('');
		setSearchCategory('');
		setSearchPriceMin('');
		setSearchPriceMax('');
		setSearchWilaya('');
		setSearchDaira('');
		setSearchCommune('');
		setSearchLocation('');
		dispatch(getAnnonces({}));
	}

	return (
		<div className="ltn__slider-area ltn__slider-4">
			<div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
				{/* ltn__slide-item */}
				<div className=" ltn__slide-item-4 bg-image bg-overlay-theme-black-60" data-bs-bg={publicUrl + "assets/img/slider/11.jpg"}>
					<div className="text-center">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 align-self-center">
									<div className="slide-item-car-dealer-form">
										<div className="ltn__car-dealer-form-tab">
											<div className="ltn__tab-menu  text-uppercase">
												{/*<div className="nav">
									<a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fas fa-home" />Rent Home</a>
									<a data-bs-toggle="tab" href="#ltn__form_tab_1_2" className><i className="fas fa-home" />Sale Home</a>
	</div>*/}
											</div>
											<div className="tab-content">
												<Spin spinning={loading} fullscreen />
												{!loading && 
												<div className='search-header'>
													<Form form={form} layout="inline" onFinish={handleFilter}>
														<Form.Item name="Titre" >
															<Input placeholder="Recherche" onChange={(e) => setSearchTitle(e.target.value)} />
														</Form.Item>

														<Form.Item name="type_de_bien">
															<Select
																loading={loading}
																placeholder="Type De Bien"
																onChange={handleShowCategory}

															>
																{stats?.categories.length > 1 &&
																	<Option key='tous' value='tous'>
																		Tous
																	</Option>}
																{stats?.categories && stats.categories.map((cat) => (
																	<Option key={cat.type_de_bien} value={cat.type_de_bien}>
																		{cat.cat_name} <Badge count={cat.count} color="#bd0000" ></Badge>
																	</Option>
																))}
															</Select>
														</Form.Item>

														<Form.Item name="transactions">
															<Select
																loading={loading}
																placeholder="Transactions"
																onChange={handleShowCategory}

															>
																{stats?.transactions.length > 1 &&
																	<Option key='tous' value='tous'>
																		Tous
																	</Option>}
																{stats?.transactions && stats.transactions.map((transaction) => (
																	<Option key={transaction.transaction} value={transaction.transaction}>
																		{transaction.transaction_name} <Badge count={transaction.count} color="#bd0000" ></Badge>
																	</Option>
																))}
															</Select>
														</Form.Item>

														{stats?.wilayas.length > 1 && <Form.Item name="wilaya">
															<Select
																loading={loading}
																placeholder="Wilaya"
																onChange={handleShowWilaya}
																defaultValue={stats?.wilayas.length === 1 ? stats.wilayas[0].wilaya : undefined}
															>

																<Option key='tous' value='tous'>
																	Tous
																</Option>
																{stats?.wilayas && stats.wilayas.map((wilaya) => (
																	<Option key={wilaya.wilaya} value={wilaya.wilaya}>
																		{wilaya.wilaya} <Badge count={wilaya.count} color="#bd0000" ></Badge>
																	</Option>
																))}
															</Select>
														</Form.Item>}

														{stats?.dairas && <Form.Item name="daira">
															<Select
																loading={loading}
																placeholder="Daira"
																onChange={handleShowDaira}
															>
																{stats?.dairas?.length > 1 &&
																	<Option key='tous' value='tous'>
																		Tous
																	</Option>}
																{searchDaira
																	? stats?.dairas?.map((daira) => (
																		daira.wilaya === searchWilaya ?
																			<Option key={daira.daira} value={daira.daira}>
																				{daira.daira} <Badge count={daira.count} color="#bd0000" ></Badge>
																			</Option>
																			: null
																	))
																	: stats?.dairas?.map((daira) => (
																		<Option key={daira.daira} value={daira.daira}>
																			{daira.daira} <Badge count={daira.count} color="#bd0000" ></Badge>
																		</Option>
																	))
																}
															</Select>
														</Form.Item>}

														{stats?.communes?.length > 1 && <Form.Item name="commune">
															<Select
																loading={loading}
																placeholder="Commune"
																onChange={handleShowCommune}
																showSearch
															>
																{stats?.communes?.length > 1 &&
																	<Option key='tous' value='tous'>
																		Tous
																	</Option>}

																{searchDaira
																	? stats?.communes?.map((commune) => (
																		commune.daira === searchDaira ?
																			<Option key={commune.commune} value={commune.commune}>
																				{commune.commune} <Badge count={commune.count} color="#bd0000" ></Badge>
																			</Option>
																			: null
																	))
																	: stats?.communes?.map((commune) => (
																		<Option key={commune.commune} value={commune.commune}>
																			{commune.commune} <Badge count={commune.count} color="#bd0000" ></Badge>
																		</Option>
																	))
																}
															</Select>
														</Form.Item>}

														<Form.Item>
															<Link className="theme-btn-1 btn btn-effect-1" type="primary" onClick={handleFilter}>
																Recherche
															</Link>
															<Link className="theme-btn-2 btn btn-effect-2" onClick={() => handleReset()}>Réinitialiser</Link>
														</Form.Item>
													</Form>
												</div>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>)

}

export default HomeBanner