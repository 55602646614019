import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

class Footer_v1 extends Component {

    componentDidMount() {

    	const $ = window.$;
    	
        let publicUrl = process.env.PUBLIC_URL+'/'
       const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){ 
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = "Footer logo"

        return (
				<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container-fluid">
				      <div className="row">
				        <div className="col-xl-4 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-about-widget">
				            <div className="footer-logo">
				              <div className="site-logo">
				                <img src={publicUrl+"assets/img/logo-2.png"} alt="Logo" />
				              </div>
							  <h4 className="footer-title">Redouanimmo</h4>
							  <div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
				                    
				                  </div>
				                  <div className="footer-address-info">
								  <i className="icon-placeholder" />
				                    <a href='https://www.google.com/maps/place/REDOUANIMMO/@36.7579592,2.9645126,19z/data=!4m14!1m7!3m6!1s0x128fb1c3cf069f0d:0x9cd4d74a58aa8891!2sREDOUANIMMO!8m2!3d36.7576498!4d2.9646467!16s%2Fg%2F11ll447dl2!3m5!1s0x128fb1c3cf069f0d:0x9cd4d74a58aa8891!8m2!3d36.7576498!4d2.9646467!16s%2Fg%2F11ll447dl2?entry=ttu'>
										N1 dely brahim chéraga <br></br>à côté hotel emir ( en face assurance SAA) 
										</a>
				                  </div>
				                </li>
				                
				              </ul>
				            </div>
				            </div>
				           
				            
				          </div>
						  
				        </div>

				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Contacts</h4>
							
				            <div className="footer-menu go-top">
				              <ul>
							  <li>
				                  
				                  <div className="footer-address-info">
				                    <p><a href="tel:020283539">Fax : 020283539</a></p>
				                  </div>
				                </li>
								<li>
				                  <div className="footer-address-info">
				                    <a href="tel:0661883856"> <i className="icon-call" /> 0661883856</a>
				                  </div>
								  <div className="footer-address-info">
				                    <a href="tel:0550807706"><i className="icon-call" /> 0550807706</a>
				                  </div>
				                
				                  <div className="footer-address-info">
				                    <a href="tel:0561168641"><i className="icon-call" /> 0561168641</a>
				                  </div>
				               
				                 
				                </li>
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title"></h4>
				            <div className="ltn__social-media mt-20">
							<div className="footer-address-info">
				                    <p><a href="mailto:redouanimmo@gmail.com"><i className="icon-mail" /> redouanimmo@gmail.com</a></p>
				                  </div>
						    	<Social />
				            </div>
				          </div>
				        </div>

				      </div>
				    </div>
				  </div>
				  <Copyright />
				</footer>
        )
    }
}


export default Footer_v1