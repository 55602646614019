import React, { Component, useState, useEffect } from 'react';
import { Form, Input, Button, Select, Badge, Flex, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import { getStats, getRecherches } from '../../../slices/annoncesSlice';
let publicUrl = process.env.PUBLIC_URL + '/'
const { Option } = Select

const SearchForm = ({ form, handleResetForm }) => {
	//const [form] = Form.useForm();
	const dispatch = useDispatch();
	const history = useHistory();

	const { idannonce } = useParams()
	const { stats, loading } = useSelector((state) => state.annonces);

	const [searchLocation, setSearchLocation] = useState('')
	const [searchWilaya, setSearchWilaya] = useState('')
	const [searchDaira, setSearchDaira] = useState('')
	const [searchCommune, setSearchCommune] = useState('')



	useEffect(() => {
		//INIT FIRST
		//dispatch(getRecherches({}))
		dispatch(getStats())
	}, [])

	useEffect(() => {
		if (stats?.wilayas.length === 1) {
			const singleWilaya = stats.wilayas[0].wilaya;
			setSearchWilaya(singleWilaya);
		}
	}, [stats?.wilayas]);

	const handleFilter = () => {
		const data = form.getFieldsValue();
	
		 // Check if format_prix is 'million' or 'milliard' and convert prices accordingly
		 if (data.format_prix === 'million') {
			data.prix_min = data.prix_min * 1e4;
			data.prix_max = data.prix_max * 1e4;
		} else if (data.format_prix === 'milliard') {
			data.prix_min = data.prix_min * 1e7;
			data.prix_max = data.prix_max * 1e7;
		}
console.log('datadatadatadatadatadatadata',data)
		dispatch(getRecherches(data)).then(() => history.push('/recherche'))
			.catch((error) => console.error(error));
		//console.log(form.getFieldsValue())
		//go to top page
		window.scrollTo(0, 0);
		//click to close ltn__utilize-close
		var element = document.getElementById("ltn__utilize-search-menu");
		element.classList.remove("ltn__utilize-open");
	}


	const handleShowWilaya = value => {
			setSearchWilaya(value)
	}
	const handleShowDaira = value => {
			setSearchDaira(value)
	}
	const handleShowCommune = value => {
			setSearchCommune(value)
	}


	const handleReset = () => {
		//form.getFieldsValue();
		form.resetFields();
		dispatch(getRecherches({}));
		//go to top page
		window.scrollTo(0, 0);
		//click to close ltn__utilize-close
		var element = document.getElementById("ltn__utilize-search-menu");
		element.classList.remove("ltn__utilize-open");
	}

	return (

		<div className="tab-content">
			{loading && <Spin spinning={loading} fullscreen />}


			<div className='search-header'>
				<Form form={form} layout="inline" loading={loading}>
					<Form.Item name="titre" >
						<Input placeholder="Recherche" />
					</Form.Item>

					<Form.Item name="type_de_bien">
						<Select
						showSearch
							loading={loading}
							placeholder="Type De Bien"
						>
							{stats?.categories.length > 1 &&
								<Option key='tous' value=''>
									Tous
								</Option>}
							{stats?.categories && stats.categories.map((cat) => (
								<Option key={cat.type_de_bien} value={cat.type_de_bien}>
									{cat.cat_name} <Badge count={cat.count} color="#bd0000" ></Badge>
								</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item name="transaction">
						
						<Select
							loading={loading}
							placeholder="Transactions"
							showSearch
						>
							{stats?.transactions.length > 1 &&
								<Option key='tous' value=''>
									Tous
								</Option>}
							{stats?.transactions && stats.transactions.map((transaction) => (
								<Option key={transaction.transaction} value={transaction.transaction}>
									{transaction.transaction_name} <Badge count={transaction.count} color="#bd0000" ></Badge>
								</Option>
							))}
						</Select>
					</Form.Item>

					{stats?.wilayas.length > 1 && <Form.Item name="wilaya">
						<Select
						showSearch
							loading={loading}
							placeholder="Wilaya"
							onChange={handleShowWilaya}
							defaultValue={stats?.wilayas.length === 1 ? stats.wilayas[0].wilaya : undefined}
						>

							<Option key='tous' value=''>
								Tous
							</Option>
							{stats?.wilayas && stats.wilayas.map((wilaya) => (
								<Option key={wilaya.wilaya} value={wilaya.wilaya}>
									{wilaya.wilaya} <Badge count={wilaya.count} color="#bd0000" ></Badge>
								</Option>
							))}
						</Select>
					</Form.Item>}

					{stats?.dairas && <Form.Item name="daira">
						<Select
							loading={loading}
							placeholder="Daira"
							onChange={handleShowDaira}
							showSearch
						>
							{stats?.dairas?.length > 1 &&
								<Option key='tous' value=''>
									Tous
								</Option>}
							{searchDaira
								? stats?.dairas?.map((daira) => (
									daira.wilaya === searchWilaya ?
										<Option key={daira.daira} value={daira.daira}>
											{daira.daira} <Badge count={daira.count} color="#bd0000" ></Badge>
										</Option>
										: null
								))
								: stats?.dairas?.map((daira) => (
									<Option key={daira.daira} value={daira.daira}>
										{daira.daira} <Badge count={daira.count} color="#bd0000" ></Badge>
									</Option>
								))
							}
						</Select>
					</Form.Item>}

					{stats?.communes?.length > 1 && <Form.Item name="commune">
						<Select
							loading={loading}
							placeholder="Commune"
							onChange={handleShowCommune}
							showSearch
						>
							{stats?.communes?.length > 1 &&
								<Option key='tous' value=''>
									Tous
								</Option>}

							{searchDaira
								? stats?.communes?.map((commune) => (
									commune.daira === searchDaira ?
										<Option key={commune.commune} value={commune.commune}>
											{commune.commune} <Badge count={commune.count} color="#bd0000" ></Badge>
										</Option>
										: null
								))
								: stats?.communes?.map((commune) => (
									<Option key={commune.commune} value={commune.commune}>
										{commune.commune} <Badge count={commune.count} color="#bd0000" ></Badge>
									</Option>
								))
							}
						</Select>

						
					</Form.Item>}
<Flex style={{width:"100%"}}>
					<Form.Item className='pricesearch' name="prix_min" >
						<Input placeholder="Prix Min" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
					<Form.Item className='pricesearch'  name="prix_max" >
						<Input placeholder="Prix Max" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
					
					
</Flex>
{<Flex style={{width:"100%"}}>
<Form.Item className="" name="format_prix">
						<Select
							loading={loading}
							placeholder="Type De Bien"
							defaultValue={'da'}
						>

								<Option key='tous' value='da'>
									DA
								</Option>
								<Option key='tous' value='million'>
									Millions
								</Option>
								<Option key='tous' value='milliard'>
									Milliards
								</Option>
						</Select>
					</Form.Item>
						</Flex>}

<Flex style={{width:"100%"}}>
					<Form.Item className='search50' name="superficie_min" >
						<Input placeholder="Superficie Min" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
					<Form.Item className='search50'  name="superficie_max" >
						<Input placeholder="Superficie Max" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
					
</Flex>

<Flex style={{width:"100%"}}>
<Form.Item className='search50' name="pieces_min" >
						<Input placeholder="Pieces Min" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
					<Form.Item className='search50'  name="pieces_max" >
						<Input placeholder="Pieces Max" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
</Flex>
<Flex style={{width:"100%"}}>
<Form.Item className='search50' name="sdb_min" >
						<Input placeholder="Salles de bain Min" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
					<Form.Item className='search50'  name="sdb_max" >
						<Input placeholder="Salles de bain max" 
						type="number"
						min="0"
						onKeyPress={(event) => { if (event.key === ',') { event.preventDefault();} }}
						/>
					</Form.Item>
</Flex>

					<div className='d-flex flex-center' style={{ width: '96%' }}>
					<Button style={{ display: 'none' }} onClick={handleFilter} htmlType="submit">
								Recherche
							</Button>
						
							<Link style={{ minWidth: '50%' }} className="theme-btn-1 btn btn-effect-1" type="primary" onClick={handleFilter} htmlType="submit">
								Recherche
							</Link>
						
						<Link style={{ minWidth: '50%' }} className="theme-btn-2 btn btn-effect-2" onClick={() => handleReset()}>Réinitialiser</Link>
					</div>
				</Form>
			</div>
		</div>)

}

export default SearchForm