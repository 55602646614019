import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Prix from '../components/Prix';
import AnnoncesSlider from '../../../section-components/AnnoncesSlider';


const AnnonceDetails = ({ annonce }) => {
	const { stats, loading } = useSelector((state) => state.annonces);

	return (<div className="ltn__shop-details-area pb-10">
		<div className="container">
			<div className="row">
				<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
						<div className="ltn__blog-meta">
							<ul><li className="ltn__blog-category">
								<Link className="bg-black" to="#">{annonce.transaction.transaction_name}</Link>
							</li>
								<li className="ltn__blog-category">
									<Link to="#">{annonce.type_de_bien.cat_name}</Link>
								</li>
								{annonce.prix &&
								<li className="ltn__blog-category">
								<Link className="bg-black" to="#">
								<b><Prix prix={annonce.prix} formatPrix={annonce.format_prix} unit_prix={annonce.unit_prix} /></b>
								</Link>
								</li>}
							</ul>
						</div>
						<h1>{annonce.titre}</h1>
						<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> <b>Commune : {annonce.commune}, Daïra : {annonce.daira}, Wilaya: {annonce.wilaya}</b></label>
						
						<h4 className="title-2">Description</h4>
						<div>{annonce.description.split('\n').map((line, i) => (
							<span key={i}>
								{line}
								<br />
							</span>
						))}</div>
					{ !annonce.description &&	<><h4 className="title-2">Details</h4>
						<div className="property-detail-info-list section-bg-1 clearfix mb-60">
    <ul>
        {annonce.id && <li><label>ID:</label> <span>{annonce.id}</span></li>}
        {annonce.pieces && <li><label>Pieces:</label> <span>{annonce.pieces}</span></li>}
        {annonce.sdb && <li><label>Salles de bains:</label> <span>{annonce.sdb}</span></li>}
        {annonce.superficie && <li><label>Superficie: </label> <span>{annonce.superficie} m&sup2;</span></li>}
        {annonce.etages !== null && (
            <li>
                <label>Etage: </label>
                <span>
                    {annonce.etages === 0 ? ' Rez de chaussée' : ` ${annonce.etages}`}
                </span>
            </li>
        )}
        {annonce.annee_construction && <li><label>Année de construction:</label> <span>{annonce.annee_construction}</span></li>}
    </ul>
    <ul>
        {annonce.superficie_batis && <li><label>Superficie batis:</label> <span>{annonce.superficie_batis}</span></li>}
        {annonce.promotion && annonce.promotion.length > 0 && (
            <li>
                <label>Promotion: </label>
                {annonce.promotion.map((promo, index) => (
                    <span key={index}>{promo.join(', ')}</span>
                ))}
            </li>
        )}
        {annonce.conditions_de_paiement && annonce.conditions_de_paiement.length > 0 && (
            <li>
                <label>Conditions de paiement:</label>
                {annonce.conditions_de_paiement.map((condition, index) => (
                    <span key={index}>{condition.join(', ')}</span>
                ))}
            </li>
        )}
        {annonce.papiers && annonce.papiers.length > 0 && (
            <li>
                <label>Papiers: </label>
                <span>{annonce.papiers.join(', ')}</span>
            </li>
        )}
    </ul>
		</div></>}
						{/*<h4 className="title-2">Spécifications</h4>
						<div className="property-detail-feature-list clearfix mb-45">
							<ul>
								{annonce.specifications && annonce.specifications.map((specification, index) => (
									<li key={index}>
										<div className="property-detail-feature-list-item">
										<label className="checkbox-item"><h6>{specification}</h6>
													<input type="checkbox" defaultChecked="checked"  disabled/>
													<span className="checkmark" />
												</label>
										</div>
									</li>
								))}

							</ul>
								</div>*/}


					</div>
				</div>
				<div className="col-lg-4">
					
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">


						{annonce.description &&
						<div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2---">
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Details</h4>
							{annonce.id && <li><label>ID:</label> <span>{annonce.id}</span></li>}
        {annonce.pieces && <li><label>Pieces:</label> <span>{annonce.pieces}</span></li>}
        {annonce.sdb && <li><label>Salles de bains:</label> <span>{annonce.sdb}</span></li>}
        {annonce.superficie && <li><label>Superficie: </label> <span>{annonce.superficie} m&sup2;</span></li>}
        {annonce.etages !== null && (
            <li>
                <label>Etage: </label>
                <span>
                    {annonce.etages === 0 ? 'Rez de chaussée' : annonce.etages}
                </span>
            </li>
        )}
        {annonce.annee_construction && <li><label>Année de construction:</label> <span>{annonce.annee_construction}</span></li>}

        {annonce.superficie_batis && <li><label>Superficie batis:</label> <span>{annonce.superficie_batis}</span></li>}
        {annonce.promotion && annonce.promotion.length > 0 && (
            <li>
                <label>Promotion: </label>
                {annonce.promotion.map((promo, index) => (
                    <span key={index}>{promo.join(', ')}</span>
                ))}
            </li>
        )}
        {annonce.conditions_de_paiement && annonce.conditions_de_paiement.length > 0 && (
            <li>
                <label>Conditions de paiement:</label>
                {annonce.conditions_de_paiement.map((condition, index) => (
                    <span key={index}>{condition.join(', ')}</span>
                ))}
            </li>
        )}
        {annonce.papiers && annonce.papiers.length > 0 && (
            <li>
                <label>Papiers: </label>
                <span>{annonce.papiers.join(', ')}</span>
            </li>
        )}
						</div>}


						
						{/* Tagcloud Widget */}
						<div className="widget ltn__tagcloud-widget go-top">
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Spécifications</h4>

							<ul>
								{annonce.specifications && annonce.specifications.map((specification, index) => (
									<li key={index}>
										<div className="property-detail-feature-list-item">
										<label className="checkbox-item"><h6>{specification}</h6>
													<input type="checkbox" defaultChecked="checked"  disabled/>
													<span className="checkmark" />
												</label>
										</div>
									</li>
								))}

							</ul>
						</div>

{/* Social Media Widget */}
<div className="widget ltn__social-media-widget">
    <h4 className="ltn__widget-title ltn__widget-title-border-2">Partager</h4>
    <div className="ltn__social-media-2">
        <ul>
            <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
            <li><a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`} target="_blank" rel="noopener noreferrer" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
        </ul>
    </div>
</div>
					</aside>
				</div>
			</div>
		</div>
	</div>)
}


export default AnnonceDetails