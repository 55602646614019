import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Form, Input, Button, Select, Badge, Flex, Spin } from 'antd';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux'
import { getAnnonces } from '../../../slices/annoncesSlice';
import { useSelector } from 'react-redux';
import CardAnnonce from '../Annonces/CardAnnonce';
import Navbar from '../../global-components/navbar';
import PageHeader from '../../global-components/page-header';
import Footer from '../../global-components/footer';


function Annonces({form,handleResetForm}) {
    const {typedebien,transaction} = useParams();
    const [category_name, setCategory_name] = useState('');

    const dispatch = useDispatch();

	const { stats, loading, annonces } = useSelector((state) => state.annonces);
    const [visible, setVisible] = useState(6); // Initial number of visible annonces

    const loadMore = () => {
        setVisible(prev => prev + 6); // Load 10 more annonces each time
    };

    useEffect(() => {
        if(stats) {
            let category, tran;

            if (typedebien) {
                category = stats.categories.find(category => category.pluriel_name.toLowerCase() === typedebien.toLowerCase());
            }
            
            if (transaction) {
                tran = stats.transactions.find(tr => tr.transaction_name.toLowerCase() === transaction.toLowerCase());
            }
            
            if (category) {
            setCategory_name(category.pluriel_name);
          }
          console.log(stats.transactions,tran)
          const query = {
            ...(tran ? { transaction: tran.transaction } : {}),
            ...(category ? { type_de_bien: category.type_de_bien } : {})
          };
          console.log('query',query);

          dispatch(getAnnonces(query));
        }
      }, [typedebien,transaction,stats]);
      
      useEffect(() => {
        if(annonces.annonces) {
            console.log('cat annonces',annonces.annonces);
            }
        }, [annonces]);

  return (
    <div> 
        <Helmet>
        <title>{category_name ? 'Annonces : ' + (transaction ? transaction + ' ' + category_name : category_name) : ''}</title>
        <meta name="description" content={`Dernières annonces ${transaction} ${category_name}`} />
        <meta name=" robots" content=" index, follow"></meta>

        <meta property="og:type" content="website" />
        <meta property="og:title" content={category_name ? 'Annonces : ' + (transaction ? transaction + ' ' + category_name : category_name) : ''} />
        <meta property="og:description" content={`Dernières annonces ${transaction} ${category_name}`} />
        <meta property="og:image" content={`${process.env.REACT_APP_WEBSITE}/assets/img/thumbnail.webp`} />
        <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/annonce/${category_name}`} />
      </Helmet>

        <Navbar form={form} handleResetForm={handleResetForm}   />
        
        <PageHeader headertitle={category_name ? 'Annonces : ' + (transaction ? transaction + ' ' + category_name : category_name) : ''} />
        <br></br>
			<div className="ltn__product-area ltn__product-gutter">
				<div className="container">
					<div className="row">
						<div className="col-lg-12  mb-100">
		{loading && <Spin spinning={loading} fullscreen />}
      							<div className="tab-content">
							<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
								<div className="row">

									{/* ltn__product-item */}
                                    {annonces.annonces 
            ? annonces.annonces.slice(0, visible).map((annonce, index) => {
                return <CardAnnonce key={index} annonce={annonce} />
            })
            : "Aucune Annonces Trouvé!"
            }

								</div>
								</div>
							</div>
							<div className="tab-pane fade" id="liton_product_list">
								<div className="ltn__product-tab-content-inner ltn__product-list-view">
								<div className="row">

									{/* ltn__product-item */}
                                    {annonces.annonces && annonces.annonces.slice(0, visible).map((annonce, index) => {
                return <CardAnnonce key={index} annonce={annonce} />
            })
                                    }
									
									
								</div>
								</div>
							</div>
							</div>
							{
							annonces.annonces && visible < annonces.annonces.length && 
							<div className="ltn__pagination-area text-center">
							<div className="ltn__pagination">
                <button onClick={loadMore} type="button" className="load-more-btn">Voir Plus</button>
           </div></div> }
							
   </div></div></div></div>

<Footer />
  </div> )
}

export default Annonces
