import React, { useEffect } from 'react'

function Prix({prix, formatPrix, unit_prix}) {

    const [prixAffiche, setPrixAffiche] = React.useState('')
    
    useEffect(() => {
        calculPrix()
    }, [prix, formatPrix])

    const calculPrix = ()=>{
		let res = formatPrix === 'million' ? 
		prix/10000 + ` ${formatPrix}` +( prix/10000 > 1.99 ? 's' : '')
		: formatPrix === 'milliard' ? 
		prix/10000000 + ` ${formatPrix}` + (prix/10000000 > 1.99 ? 's' : '')
		: prix +' DA'

		return setPrixAffiche(res) //=> `${res}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
  return (
    <>
      {prixAffiche} {unit_prix && <> / {unit_prix}</>}
    </>
  )
}

export default Prix
