import axios from 'axios';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { createDirectus, rest, readItems, readItem } from '@directus/sdk';
const client = createDirectus(process.env.REACT_APP_API_URL).with(rest());

const wilayas = [
	{ num: 1, name: 'Adrar' },
	{ num: 2, name: 'Chlef' },
	{ num: 3, name: 'Laghouat' },
	{ num: 4, name: 'Oum El Bouaghi' },
	{ num: 5, name: 'Batna' },
	{ num: 6, name: 'Béjaïa' },
	{ num: 7, name: 'Biskra' },
	{ num: 8, name: 'Béchar' },
	{ num: 9, name: 'Blida' },
	{ num: 10, name: 'Bouira' },
	{ num: 11, name: 'Tamanrasset' },
	{ num: 12, name: 'Tébessa' },
	{ num: 13, name: 'Tlemcen' },
	{ num: 14, name: 'Tiaret' },
	{ num: 15, name: 'Tizi Ouzou' },
	{ num: 16, name: 'Alger' },
	{ num: 17, name: 'Djelfa' },
	{ num: 18, name: 'Jijel' },
	{ num: 19, name: 'Sétif' },
	{ num: 20, name: 'Saïda' },
	{ num: 21, name: 'Skikda' },
	{ num: 22, name: 'Sidi Bel Abbès' },
	{ num: 23, name: 'Annaba' },
	{ num: 24, name: 'Guelma' },
	{ num: 25, name: 'Constantine' },
	{ num: 26, name: 'Médéa' },
	{ num: 27, name: 'Mostaganem' },
	{ num: 28, name: 'M’Sila' },
	{ num: 29, name: 'Mascara' },
	{ num: 30, name: 'Ouargla' },
	{ num: 31, name: 'Oran' },
	{ num: 32, name: 'El Bayadh' },
	{ num: 33, name: 'Illizi' },
	{ num: 34, name: 'Bordj Bou Arreridj' },
	{ num: 35, name: 'Boumerdès' },
	{ num: 36, name: 'El Tarf' },
	{ num: 37, name: 'Tindouf' },
	{ num: 38, name: 'Tissemsilt' },
	{ num: 39, name: 'El Oued' },
	{ num: 40, name: 'Khenchela' },
	{ num: 41, name: 'Souk Ahras' },
	{ num: 42, name: 'Tipaza' },
	{ num: 43, name: 'Mila' },
	{ num: 44, name: 'Aïn Defla' },
	{ num: 45, name: 'Naâma' },
	{ num: 46, name: 'Aïn Témouchent' },
	{ num: 47, name: 'Ghardaïa' },
	{ num: 48, name: 'Relizane' },
	{ num: 49, name: 'El M\'ghair' },
	{ num: 50, name: 'El Menia' },
	{ num: 51, name: 'Ouled Djellal' },
	{ num: 52, name: 'Bordj Baji Mokhtar' },
	{ num: 53, name: 'Béni Abbès' },
	{ num: 54, name: 'Timimoun' },
	{ num: 55, name: 'Touggourt' },
	{ num: 56, name: 'Djanet' },
	{ num: 57, name: 'In Salah' },
	{ num: 58, name: 'In Guezzam' },
];

const defaultLimit = 300;
const defaultPage = 1;

const initialState = {
    loading: false,
    error: '',
    success: '',
    rechercheAnnonces:[],
    annonces:[],
    annonce: null,
    page : null,
    totalPages: null,
    limit: null,
    wilayas : wilayas,
    stats:null
};

export const getStats = createAsyncThunk('annonces/getStats', async () => {
    return await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_X}/stats/${process.env.REACT_APP_USER}`
    })
        .then(function (response) {
            if(response.data.error){
                throw response.data.error
            }
            return response.data;
        })
});

export const getAnnonces = createAsyncThunk('annonces/getAnnonces', async (data) => {
    //const {searchTitle,searchCategory,searchTransaction,searchWilaya} = data

    const orfilters = [];
	const andfilters = [];

    andfilters.push({
        user_created: {
            _eq: process.env.REACT_APP_USER,
        },
    });

    if (data.titre) {
        orfilters.push(
            {
                titre: {
                    _contains: data.titre,
                },
            },
            {
                description: {
                    _contains: data.titre,
                },
            },
        );
    }

    if (data.type_de_bien) {
        andfilters.push({
            type_de_bien: {
                _eq: data.type_de_bien,
            },
        });
    }

    if (data.wilaya) {
        andfilters.push(
            {
                wilaya: {
                    _eq: data.wilaya,
                },
            }
        );
    }
    if (data.daira) {
        andfilters.push(
            {
                daira: {
                    _eq: data.daira,
                },
            }
        );
    }
    if (data.commune) {
        andfilters.push(
            {
                commune: {
                    _eq: data.commune,
                },
            }
        );
    }
    if (data.prix_min) {
        andfilters.push({
            prix: {
                _gte: data.prix_min,
            },
        });
    }
    if (data.prix_max) {
        andfilters.push({
            prix: {
                _lte: data.prix_max,
            },
        });
    }
    if (data.superficie) {
        andfilters.push({
            superficie: {
                _lte: data.superficie,
            },
        });
    }

    if (data.superficie_max) {
        andfilters.push({
            superficie: {
                _lte: data.superficie_max,
            },
        });
    }
    if (data.superficie_min) {
        andfilters.push({
            superficie: {
                _gte: data.superficie_min,
            },
        });
    }

    if (data.pieces) {
        andfilters.push({
            pieces: {
                _lte: data.pieces,
            },
        });
    }

    if (data.pieces_max) {
        andfilters.push({
            pieces: {
                _lte: data.pieces_max,
            },
        });
    }
    if (data.pieces_min) {
        andfilters.push({
            pieces: {
                _gte: data.pieces_min,
            },
        });
    }

    if (data.sdb_max) {
        andfilters.push({
            sdb: {
                _lte: data.sdb_max,
            },
        });
    }
    if (data.sdb_min) {
        andfilters.push({
            sdb: {
                _gte: data.sdb_min,
            },
        });
    }
    if (data.sdb) {
        andfilters.push({
            sdb: {
                _gte: data.sdb,
            },
        });
    }
    if (data.etages) {
        andfilters.push({
            etages: {
                _gte: data.etages,
            },
        });
    }

    if (data.transaction) {
        andfilters.push({
            transaction: {
                _eq: data.transaction,
            },
        });
    }

    let filter = {};
    if (orfilters.length > 0) {
        filter._or = orfilters;
    }
    if (andfilters.length > 0) {
        filter._and = andfilters;
    }

    try {
        const result = await client.request(
            readItems('annonces_immo', {
                filter: filter,
                limit: data.limit || defaultLimit,
                page: data.page || defaultPage,
                sort: ['sort', '-date_updated'],
                fields: [
                    '*',
                    'images.*',
                    'localisation.*',
                    'type_de_bien.cat_name',
                    'transaction.transaction_name',
                ],
            },
            )
        );
        const countresult = await client.request(
            readItems('annonces_immo', {
                filter: filter,
                limit: data.limit || defaultLimit,
                page: data.page || defaultPage,
                sort: ['sort', '-date_updated'],
                aggregate: { count: '*' },
                fields: [
                    '*',
                    'images.*',
                    'localisation.*',
                    'type_de_bien.cat_name',
                    'transaction.transaction_name',
                ],
            },
            )
        );

        return {
            annonces:result, 
            page: data.page || defaultPage, 
            limit: data.limit || defaultLimit, 
            total: countresult[0].count,
            totalPages : Math.ceil(countresult[0].count / (data.limit || defaultLimit))
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des annonces_immo :', error);
    } 

})

export const getRecherches = createAsyncThunk('annonces/getRecherches', async (data) => {
    //const {searchTitle,searchCategory,searchTransaction,searchWilaya} = data

    const orfilters = [];
	const andfilters = [];

    andfilters.push({
        user_created: {
            _eq: process.env.REACT_APP_USER,
        },
    });

    if (data.titre) {
        orfilters.push(
            {
                titre: {
                    _contains: data.titre,
                },
            },
            {
                description: {
                    _contains: data.titre,
                },
            },
        );
    }

    if (data.type_de_bien) {
        andfilters.push({
            type_de_bien: {
                _eq: data.type_de_bien,
            },
        });
    }

    if (data.wilaya) {
        andfilters.push(
            {
                wilaya: {
                    _eq: data.wilaya,
                },
            }
        );
    }
    if (data.daira) {
        andfilters.push(
            {
                daira: {
                    _eq: data.daira,
                },
            }
        );
    }
    if (data.commune) {
        andfilters.push(
            {
                commune: {
                    _eq: data.commune,
                },
            }
        );
    }
    if (data.prix_min) {
        andfilters.push({
            prix: {
                _gte: data.prix_min,
            },
        });
    }
    if (data.prix_max) {
        andfilters.push({
            prix: {
                _lte: data.prix_max,
            },
        });
    }
    if (data.superficie) {
        andfilters.push({
            superficie: {
                _lte: data.superficie,
            },
        });
    }

    if (data.superficie_max) {
        andfilters.push({
            superficie: {
                _lte: data.superficie_max,
            },
        });
    }
    if (data.superficie_min) {
        andfilters.push({
            superficie: {
                _gte: data.superficie_min,
            },
        });
    }

    if (data.pieces) {
        andfilters.push({
            pieces: {
                _lte: data.pieces,
            },
        });
    }

    if (data.pieces_max) {
        andfilters.push({
            pieces: {
                _lte: data.pieces_max,
            },
        });
    }
    if (data.pieces_min) {
        andfilters.push({
            pieces: {
                _gte: data.pieces_min,
            },
        });
    }

    if (data.sdb_max) {
        andfilters.push({
            sdb: {
                _lte: data.sdb_max,
            },
        });
    }
    if (data.sdb_min) {
        andfilters.push({
            sdb: {
                _gte: data.sdb_min,
            },
        });
    }
    if (data.sdb) {
        andfilters.push({
            sdb: {
                _gte: data.sdb,
            },
        });
    }
    if (data.etages) {
        andfilters.push({
            etages: {
                _gte: data.etages,
            },
        });
    }

    if (data.transaction) {
        andfilters.push({
            transaction: {
                _eq: data.transaction,
            },
        });
    }

    let filter = {};
    if (orfilters.length > 0) {
        filter._or = orfilters;
    }
    if (andfilters.length > 0) {
        filter._and = andfilters;
    }

    try {
        const result = await client.request(
            readItems('annonces_immo', {
                filter: filter,
                limit: data.limit || defaultLimit,
                page: data.page || defaultPage,
                sort: ['sort', '-date_updated'],
                fields: [
                    '*',
                    'images.*',
                    'localisation.*',
                    'type_de_bien.cat_name',
                    'transaction.transaction_name',
                ],
            },
            )
        );
        const countresult = await client.request(
            readItems('annonces_immo', {
                filter: filter,
                limit: data.limit || defaultLimit,
                page: data.page || defaultPage,
                sort: ['sort', '-date_updated'],
                aggregate: { count: '*' },
                fields: [
                    '*',
                    'images.*',
                    'localisation.*',
                    'type_de_bien.cat_name',
                    'transaction.transaction_name',
                ],
            },
            )
        );

        return {
            annonces:result, 
            page: data.page || defaultPage, 
            limit: data.limit || defaultLimit, 
            total: countresult[0].count,
            totalPages : Math.ceil(countresult[0].count / (data.limit || defaultLimit))
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des annonces_immo :', error);
    } 

})

export const getAnnonce = createAsyncThunk('annonces/getAnnonce', async (annonceId) => {
    return await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/annonce/${annonceId}`,
        withCredentials: true
    })
        .then(function (response) {
            if(response.data.error){
                throw response.data.error
            }
            return response.data;
        })

})

export const getCategories = createAsyncThunk('annonces/getCategories', async (data) => {
    return await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/annonce?perpage=${data.perpage}&page=${data.page}`,
        data: data,
        withCredentials: true
    })
        .then(function (response) {
            if(response.data.error){
                throw response.data.error
            }
            return response.data;
        })

})

const annoncesSlice = createSlice({
    name: "annonce",
    initialState,
    reducers: {
        removeError: (state)=>{
            state.error = ''
        },
        removeSuccess: (state)=>{
            state.success = ''
        },
        setCurrentPage: (state,action)=>{
            state.currentPage = action.payload
            //console.log(action.payload)
        },
        setRechercheAnnonces: (state,action)=>{
            state.rechercheAnnonces = action.payload
            //console.log(action.payload)
        }
    },
    extraReducers: (builder) => { 
        /* GET USER STATS */
        builder.addCase(getStats.pending, (state)=>{
            state.loading = true
        })
        builder.addCase(getStats.fulfilled, (state,action)=>{
            state.loading = false
            state.error = ''
            state.stats = action.payload
        })
        builder.addCase(getStats.rejected, (state,action)=>{
            state.loading = false
            state.error = action.error.message
        })

            /*  GET POSTS  */
        builder.addCase(getAnnonces.pending, (state)=>{
            state.loading = true
        })
        builder.addCase(getAnnonces.fulfilled, (state,action)=>{
            state.loading = false
            state.error = ''
            state.annonces = action.payload
            console.log(action.payload)
        })
        builder.addCase(getAnnonces.rejected, (state,action)=>{
            state.loading = false
            state.error = action.error.message
            //console.log('errrroooor',action.error)
        })

             /*  GET POSTS RECHERCHES  */
             builder.addCase(getRecherches.pending, (state)=>{
                state.loading = true
            })
            builder.addCase(getRecherches.fulfilled, (state,action)=>{
                state.loading = false
                state.error = ''
                state.rechercheAnnonces = action.payload.annonces
                console.log(action.payload)
            })
            builder.addCase(getRecherches.rejected, (state,action)=>{
                state.loading = false
                state.error = action.error.message
                //console.log('errrroooor',action.error)
            })


        /* GET SINGLE POST */ 
        builder.addCase(getAnnonce.pending, (state)=>{
            state.loading = true
        })
        builder.addCase(getAnnonce.fulfilled, (state,action)=>{
            state.loading = false
            state.annonce = action.payload
            //console.log(action.payload)
        })
        builder.addCase(getAnnonce.rejected, (state,action)=>{
            state.loading = false
            state.error = action.error.message
            //console.log('errrroooor',action.error)
        })

}
});

export default annoncesSlice.reducer;
export const {removeError,removeSuccess,setCurrentPage,setRechercheAnnonces} = annoncesSlice.actions