import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import React from 'react';
import AnnonceSlider from './single-annonce/AnnonceSlider';
import Navbar from '../../global-components/navbar';
import PageHeader from '../../global-components/page-header';
import ProductSlider from '../../shop-components/product-slider-v1';
import Annoncedetails from './single-annonce/Annoncedetails';
import CallToActionV1 from '../../section-components/call-to-action-v1';
import Footer from '../../global-components/footer';

import { createDirectus, rest, readItems, readItem } from '@directus/sdk';
const client = createDirectus(process.env.REACT_APP_API_URL).with(rest());

const Annonce = ({ form, handleResetForm }) => {
  const { idannonce } = useParams();
  const [annonce, setAnnonce] = useState(null);

  const isNumbersOnly = /^\d+$/.test(idannonce);

  const getAnnonceSlug = async (idannonce) => {
    try {
      const result = await client.request(
        readItems('annonces_immo', {
          filter: {
            slug_title: {
              _eq: idannonce,
            },
            user_created: {
              _eq: process.env.REACT_APP_USER,
            }
          },
          sort: ['sort', '-date_updated'],
          fields: [
            '*',
            'images.*',
            'localisation.*',
            'type_de_bien.cat_name',
            'transaction.transaction_name',
          ],
        })
      );

      setAnnonce(result[0]); // return the result or use it in some way

    } catch (error) {
      console.error('Erreur lors de la récupération des annonces_immo :', error);
    }
  }

  useEffect(() => {
    if (isNumbersOnly) {
      console.log('isNumbersOnly');
    } else {
      getAnnonceSlug(idannonce);
      console.log('isNotNumbersOnly');
    }

  }, [isNumbersOnly]);

  useEffect(() => {
    if (annonce) {


      //console.log('annonce',annonce,annonce.images);
    }

  }, [annonce]);
  return (<div>
    {annonce && <>

      <Helmet>
        <title>{annonce.titre}</title>
        <meta name="description" content={annonce.description} />
        <meta name=" robots" content=" index, follow"></meta>

        <meta property="og:type" content="article" />
        <meta property="og:title" content={annonce.titre} />
        <meta property="og:description" content={annonce.description} />
        <meta property="og:image" content={annonce.thumbnail ? `${process.env.REACT_APP_API_IMAGE}/image/${process.env.REACT_APP_USER}/bigthumbnail/${annonce.thumbnail}.jpg` : `${process.env.REACT_APP_WEBSITE}/assets/img/thumbnail.webp`} />
        <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/annonce/${annonce.slug_title}`} />
      </Helmet>

      <Navbar form={form} handleResetForm={handleResetForm} />
      {/*<PageHeader headertitle={annonce.titre} customclass="mb-0" />*/}
     {annonce.images && <AnnonceSlider images={annonce.images} title={annonce.titre} />}
      <Annoncedetails annonce={annonce} />
      {/*<CallToActionV1 />*/}
      <Footer />
    </>}
  </div>)
}

export default Annonce
