import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/profile.php?id=100092594625103"  target="_blank" title="Facebook"><img className='social-icon' src="/assets/img/social/fb.svg" /></a></li>
				<li><a href="https://www.instagram.com/redouanimmo"  target="_blank"  title="Instagram"><img className='social-icon' src="/assets/img/social/ig.svg" /></a></li>
				<li><a href="https://www.linkedin.com/in/redouan-immo-781240318"  target="_blank"  title="linkedin"><img className='social-icon' src="/assets/img/social/li.svg" /></a></li>
				<li><a href="#"  target="_blank"  title="youtube"><img className='social-icon' src="/assets/img/social/yt.svg" /></a></li>
				<li><a href="https://www.tiktok.com/@redouanimmo" target="_blank" title="tiktok"><img className='social-icon' src="/assets/img/social/tk.svg" /></a></li>

			</ul>
		</div>
        }
}

export default Social