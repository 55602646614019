import { createDirectus, rest, readItems, readItem } from '@directus/sdk';
const client = createDirectus(process.env.REACT_APP_API_URL).with(rest());

const getAnn = async (data) => {
    const defaultLimit = 3;
    const defaultPage = 1;

    const orfilters = [];
    const andfilters = [];


    andfilters.push({
        user_created: {
            _eq: process.env.REACT_APP_USER,
        },
    });

    if (data.searchTitle) {
        orfilters.push(
            {
                titre: {
                    _contains: data.searchTitle,
                },
            },
            {
                description: {
                    _contains: data.searchTitle,
                },
            },
        );
    }

    if (data.searchCategory) {
        andfilters.push({
            type_de_bien: {
                _eq: data.searchCategory,
            },
        });
    }

    if (data.searchWilaya) {
        andfilters.push(
            {
                wilaya: {
                    _eq: data.searchWilaya,
                },
            }
        );
    }
    if (data.searchDaira) {
        andfilters.push(
            {
                daira: {
                    _eq: data.searchDaira,
                },
            }
        );
    }
    if (data.searchCommune) {
        andfilters.push(
            {
                commune: {
                    _eq: data.searchCommune,
                },
            }
        );
    }
    if (data.searchPriceMin) {
        andfilters.push({
            prix: {
                _gte: data.searchPriceMin,
            },
        });
    }
    if (data.searchPriceMax) {
        andfilters.push({
            prix: {
                _lte: data.searchPriceMax,
            },
        });
    }
    if (data.searchSuperficie) {
        andfilters.push({
            superficie: {
                _lte: data.searchSuperficie,
            },
        });
    }
    if (data.searchPieces) {
        andfilters.push({
            pieces: {
                _gte: data.searchPieces,
            },
        });
    }
    if (data.searchSdb) {
        andfilters.push({
            sdb: {
                _gte: data.searchSdb,
            },
        });
    }
    if (data.searchEtages) {
        andfilters.push({
            etages: {
                _gte: data.searchEtages,
            },
        });
    }

    if (data.searchTransaction) {
        andfilters.push({
            transaction: {
                _eq: data.searchTransaction,
            },
        });
    }

    let filter = {};
    if (orfilters.length > 0) {
        filter._or = orfilters;
    }
    if (andfilters.length > 0) {
        filter._and = andfilters;
    }
    try {
        const result = await client.request(

            readItems('annonces_immo', {
                filter: filter,
                limit: data.limit || defaultLimit,
                page: data.page || defaultPage,
                sort: ['sort', '-date_updated'],
                fields: [
                    '*',
                    'images.*',
                    'localisation.*',
                    'type_de_bien.cat_name',
                    'transaction.transaction_name',
                ],
            },
            )
        );
console.log(result)
        return(result);
    } catch (error) {
        console.error('Erreur lors de la récupération des annonces_immo :', error);
    }

}

export { getAnn };