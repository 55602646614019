import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ShopRightSidebar from './shop-components/shop-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import SearchResultRightSidebar from './global-components/SearchResultRightSidebar';
import HomeBanner from './section-components/HomeBanner';

const Recherche = ({form, handleResetForm}) => {
    return <div>
        <Navbar form={form} handleResetForm={handleResetForm}   />
        
        {<PageHeader headertitle="Resultat des recherches"  />}
        <SearchResultRightSidebar form={form} handleResetForm={handleResetForm} />
        {/*<CallToActionV1 />*/}
        <Footer />
    </div>
}

export default Recherche

