import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Social from '../section-components/social';
import SearchForm from './Search/SearchForm';

let publicUrl = process.env.PUBLIC_URL+'/'
const Navbar =({form,handleResetForm})=>{
	const { stats, loading } = useSelector((state) => state.annonces);
	useEffect(() => {
		if (stats) {
			console.log(stats.categories)
		} 
	}, [stats])
return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
			<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
				<div className="container">
				<div className="row ">
					<div className="col-md-7">
					<div className="ltn__top-bar-menu">
						<ul>
						<li><a href="mailto:redouanimmo@gmail.com"><i className="icon-mail" />redouanimmo@gmail.com</a></li>
						<li><a target='_blank' href="https://www.google.com/maps/place/REDOUANIMMO/@36.7579592,2.9645126,19z/data=!4m14!1m7!3m6!1s0x128fb1c3cf069f0d:0x9cd4d74a58aa8891!2sREDOUANIMMO!8m2!3d36.7576498!4d2.9646467!16s%2Fg%2F11ll447dl2!3m5!1s0x128fb1c3cf069f0d:0x9cd4d74a58aa8891!8m2!3d36.7576498!4d2.9646467!16s%2Fg%2F11ll447dl2?entry=ttu"><i className="icon-placeholder" /> N1 dely brahim chéraga en face assurance SAA</a></li>
						</ul>
					</div>
					</div>
					<div className="col-md-5">
					<div className="top-bar-right text-end">
						<div className="ltn__top-bar-menu">
						<ul>
							<li className="d-none">
							{/* ltn__language-menu */}
							<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
								<ul>
								<li><a href="#" className="dropdown-toggle"><span className="active-currency">English</span></a>
									<ul>
									{/*<li><Link to="#">Arabic</Link></li>
									<li><Link to="#">Bengali</Link></li>
									<li><Link to="#">Chinese</Link></li>
									<li><Link to="#">English</Link></li>*/}
									<li><Link to="#">French</Link></li>
									
									</ul>
								</li>
								</ul>
							</div>
							</li>
							<li>
								<Social />
							</li>
							<li>
							{/* header-top-btn */}
							{/*<div className="header-top-btn">
								<Link to="/add-listing">Add Listing</Link>
		</div>*/}
							</li>
						</ul>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
				<div className="container">
				<div className="row header-bar" >
					<div className="col">
					<div className="site-logo-wrap">
						<div className="site-logo go-top">
						<Link to="/"><img style={{marginRight:5}} src={publicUrl+"assets/img/logo.png"} alt="Logo" /> Agréé par l'état</Link>

						</div>
						
						<div className="get-support clearfix d-none">
						<div className="get-support-icon">
							<i className="icon-call" />
						</div>
						<div className="get-support-info">
							<h6>Get Support</h6>
							<h4><a href="tel:+123456789">123-456-789-10</a></h4>
						</div>
						</div>
					</div>
					</div>
					<div className="col header-menu-column">
					<div className="header-menu d-none d-xl-block">
						<nav>
						<div className="ltn__main-menu go-top">
							<ul>
							<li className=""><Link to="/">Accueil</Link></li>
							<li><Link to="/annonces">Annonces</Link></li>
							
							<li className="menu-icon"><Link to="#">Type de biens</Link>
								<ul>
								{stats && stats.categories && stats.categories.map((cat, index) => (
							  <li><Link to={`/annonces/${cat.pluriel_name}`}>{cat.pluriel_name}</Link></li>
                            ))}
								
								</ul>
							</li>
							{/*<li className="menu-icon"><Link to="/shop">Shop</Link>
								<ul>
									<li><Link to="/shop">Shop</Link></li>
									<li><Link to="/shop-grid">Shop Grid</Link></li>
									<li><Link to="/shop-left-sidebar">Shop Left sidebar</Link></li>
									
									<li><Link to="/product-details">Shop Details</Link></li>
									<li><Link to="/cart">Cart</Link></li>
									<li><Link to="/checkout">Checkout</Link></li>
									<li><Link to="/my-account">My Account</Link></li>
									<li><Link to="/login">Sign in</Link></li>
									<li><Link to="/register">Register</Link></li>
								</ul>
							</li>
							<li className="menu-icon"><Link to="/blog-grid">News</Link>
								<ul>
									<li><Link to="/blog">News</Link></li>
									<li><Link to="/blog-grid">News Grid</Link></li>
									<li><Link to="/blog-left-sidebar">News Left sidebar</Link></li>
									<li><Link to="/blog-right-sidebar">News Right sidebar</Link></li>
									<li><Link to="/blog-details">News details</Link></li>
								</ul>
							</li>
							<li className="menu-icon"><Link to="#">Pages</Link>
								<ul className="mega-menu">
									<li><a href="#">Inner Pages</a>
										<ul>
											<li><Link to="/portfolio">Portfolio</Link></li>
											<li><Link to="/portfolio-v2">Portfolio - 02</Link></li>
											<li><Link to="/portfolio-details">Portfolio Details</Link></li>
											<li><Link to="/team">Team</Link></li>
											<li><Link to="/team-details">Team Details</Link></li>
											<li><Link to="/faq">FAQ</Link></li>
										</ul>
									</li>
									<li><Link to="#">Inner Pages</Link>
										<ul>
											<li><Link to="/history">History</Link></li>
											<li><Link to="/add-listing">Add Listing</Link></li>
											<li><Link to="/location">Google Map Locations</Link></li>
											<li><Link to="/404">404</Link></li>
											<li><Link to="/contact">Contact</Link></li>
											<li><Link to="/coming-soon">Coming Soon</Link></li>
										</ul>
									</li>
									<li><Link to="#">Shop Pages</Link>
										<ul>
											<li><Link to="/shop">Shop</Link></li>
											<li><Link to="/shop-left-sidebar">Shop Left sidebar</Link></li>
											<li><Link to="/shop-right-sidebar">Shop right sidebar</Link></li>
											<li><Link to="/shop-grid">Shop Grid</Link></li>
											<li><Link to="/product-details">Shop details </Link></li>
											<li><Link to="/cart">Cart</Link></li>
										</ul>
									</li>
									<li><Link to="/shop"><img src={publicUrl+"assets/img/banner/menu-banner-1.jpg"} alt="#" /></Link>
									</li>
								</ul>
							</li>
	<li><Link to="/contact">Contact</Link></li>*/}
							</ul>
						</div>
						</nav>
					</div>
					</div>
					<div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
					{/* header-search-1 
					<div className="header-search-wrap">
						<div className="header-search-1">
						<div className="search-icon">
							<i className="icon-search for-search-show" />
							<i className="icon-cancel  for-search-close" />
						</div>
						</div>
						<div className="header-search-1-form">
						<form id="#" method="get" action="#">
							<input type="text" name="search" defaultValue placeholder="Search here..." />
							<button type="submit">
							<span><i className="icon-search" /></span>
							</button>
						</form>
						</div>
					</div>*/}
					{/* user-menu 
					<div className="ltn__drop-menu user-menu">
						<ul>
						<li>
							<Link to="#"><i className="icon-user" /></Link>
							<ul className="go-top">
							<li><Link to="/login">Sign in</Link></li>
							<li><Link to="/register">Register</Link></li>
							<li><Link to="/my-account">My Account</Link></li>
							</ul>
						</li>
						</ul>
					</div>*/}
					{/* mini-cart */}
					<div className="mini-cart-icon">
                            <a href="#ltn__utilize-search-menu" className="ltn__utilize-toggle">
							<i className="icon-search for-search-show" />
                            </a>
                    </div>
					{/* mini-cart */}
					{/* Mobile Menu Button */}
					<div className="mobile-menu-toggle d-xl-none">
						<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
						<svg viewBox="0 0 800 600">
							<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
							<path d="M300,320 L540,320" id="middle" />
							<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
						</svg>
						</a>
					</div>
					</div>
				</div>
				</div>
			</div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/logo.png"} alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
					
					<div className="ltn__utilize-menu">
					<ul>
						<li><a href="/">Home</a>
						</li>
						<li><Link to="/annonces">annonces</Link>
						</li>
						<div className="ltn__utilize-buttons ltn__utilize-buttons-2">
						<li><Link to="#">Types de biens : </Link>
						<ul className="sub-menu">
						

								{stats && stats.categories && stats.categories.map((cat, index) => (
							  <li ><Link to={`/annonces/${cat.pluriel_name}`}>{cat.pluriel_name}</Link></li>
                            ))}
								

							
						</ul>
						</li></div>

					</ul>
					</div>
					{/*<div className="ltn__social-media-2">
					<ul>
						<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
								</div>*/}
				</div>
			</div>

					{/* Utilize Cart Menu Start */}
					<div id="ltn__utilize-search-menu" className="ltn__utilize ltn__utilize-search-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
				<div className="ltn__utilize-menu-head">
					<span className="ltn__utilize-menu-title">Recherche</span>
					<button className="ltn__utilize-close">×</button>
				</div>

				

				<div className="mini-cart-footer">
					<SearchForm form={form} handleResetForm={handleResetForm}/>
					

				</div>
				</div>
			</div>
			{/* Utilize Cart Menu End */}

		</div>
        )
    }



export default Navbar