import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import CardAnnonceSlider from '../global-components/Annonces/CardAnnonceSlider';
import { getAnn } from '../../services/annonces';

import { useSelector } from 'react-redux';

let publicUrl = process.env.PUBLIC_URL + '/'

const AnnoncesSlider = ({ type_de_bien, titre, style }) => {
    const [annonces, setAnnonces] = useState([])
    useEffect(() => {
        const fetchAnnonces = async () => {
            if (type_de_bien) {
                const result = await getAnn({ searchCategory: type_de_bien,limit: 3 });
                setAnnonces(result);
                console.log(result)
            }
        }

        fetchAnnonces();
    }, [])



    return (<div style={style ? style : null}>
	{annonces.length > 0 && 
			<div className="container ltn__product-slider-area ltn__product-gutter pt-50 pb-45 plr--3">
			  <div className="container-fluid">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			         <h1 className="section-title">{titre}</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
               
               {annonces.length > 0
                            ? annonces.map((annonce, index) => {
                                return <CardAnnonceSlider key={index} annonce={annonce} />
                            })
                            : "Aucune Annonces correspondant a votre recherche n'a été Trouvé!"
                        }
        
			    </div>
			  </div>
			</div>

                    }
		   </div>)

}

export default AnnoncesSlider