import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Footer from '../../../global-components/footer';

const imageUrl = process.env.REACT_APP_API_IMAGE
const agentId = process.env.REACT_APP_USER 

const AnnonceSlider = (images,title)=> {
const [photos, setPhotos] = useState([])
	useEffect(() => {
		if(images){console.log('images',images.images)
		setPhotos(images.images)}
	}, [images])

	useEffect(() => {
		if(photos){
	console.log('photos',photos[0])
}
	}, [photos])

    return (<>
<div className="ltn__img-slider-area mb-30">
				<div className="container-fluid">
				<div className="row ltn__image-slider-3-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
					
					{photos.length > 1 &&
							photos.map((image, index) => {
								return (
								<div className="col-lg-12">
									<div className={`ltn__img-slide-item-5`}>
									<a href={`${imageUrl}/image/${agentId}/original/${image.directus_files_id}.jpg`} data-rel="lightcase:myCollection">
									<img src={`${imageUrl}/image/${agentId}/bigcarousel/${image.directus_files_id}.jpg`} alt={title} />
									</a>
								</div>
									</div>)
						}
						)
					}
					
					{ photos.length === 1 &&
						<div className="col-lg-12">
									<div className={`ltn__img-slide-item-12`}>
									<a href={`${imageUrl}/image/${agentId}/original/${photos[0].directus_files_id}.jpg`} data-rel="lightcase:myCollection">
									<img src={`${imageUrl}/image/${agentId}/bigcarousel/${photos[0].directus_files_id}.jpg`} alt={title} />
									</a>
								</div>
									</div>
				}
					
				</div>
				</div>
				</div>

				</>)
        }


export default AnnonceSlider