import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBath, faBed, faRulerCombined, faLayerGroup,faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import ImageCard from './components/ImageCard';
import Prix from './components/Prix';

let publicUrl = process.env.PUBLIC_URL + '/'
function CardAnnonceSlider({ annonce, ...props }) {
	return (
		
<div className="col-lg-4">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
					  <Link to={`/annonce/${annonce.slug_title}`}>
						<ImageCard thumbnail={annonce.thumbnail} titre={annonce.titre} />
					</Link><div className="product-badge">
			              <ul>
						  <li className="sale-badge bg-red">{annonce.transaction.transaction_name}</li>
			                <li className="sale-badge bg-black">{annonce.type_de_bien.cat_name}</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to={`/annonce/${annonce.slug_title}`}><i className="flaticon-pin" /> {annonce.localisation.commune_name_ascii}, {annonce.localisation.daira_name_ascii}, {annonce.localisation.wilaya_name_ascii}</Link>
			                  </li>
			                </ul>
			              </div>

			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
						<Prix prix={annonce.prix} formatPrix={annonce.format_prix} unit_prix={annonce.unit_prix} />
			            </div>

			            <h2 className="product-title go-top">
						<Link to={`/annonce/${annonce.slug_title}`}>{annonce.titre}</Link>
							</h2>

						<ul className="ltn__list-item-2 ltn__list-item-2-before">
						{annonce.pieces && <li><span><FontAwesomeIcon icon={faBed} /> {annonce.pieces} </span>
							
						</li>}
						{annonce.sdb && <li><span><FontAwesomeIcon icon={faBath} /> {annonce.sdb} </span>
							
						</li>}
						{annonce.superficie && <li><span><FontAwesomeIcon icon={faRulerCombined} /> {annonce.superficie} m<sup>2</sup> </span>
							
						</li>}
						<li><span>
							{annonce.etages !== null && (<>
								<FontAwesomeIcon icon={faLayerGroup} />
								
									{annonce.etages === 0
										? ' Rez de chaussée'
										: `Etage ${annonce.etages}`}
							</>)}

							</span></li>
					</ul>
			          </div>

			        </div>

			      </div>
		

	)
}

export default CardAnnonceSlider
