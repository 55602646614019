import React,{useEffect} from 'react';
import { useSelector } from 'react-redux';

import Navbar from './global-components/navbar';
import HomeBanner from './section-components/HomeBanner';
import ProductListing from './section-components/product-listing';
import Featuresv1 from './section-components/features-v1';
import ProSlider from './section-components/product-slider-v1';
import HomeTabs from './section-components/HomeTabs';
import VideoV1 from './section-components/video-v1';
import Category from './section-components/category-v1';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';


import AnnoncesSlider from './section-components/AnnoncesSlider';


const Accueil = ({form, handleResetForm}) => {
    const { stats, loading } = useSelector((state) => state.annonces);


    useEffect(() => {
        if(stats){
            console.log('statssss',stats)
        }
    }, [stats])

    return <div >
        <Navbar form={form} handleResetForm={handleResetForm} CustomClass="ltn__header-transparent gradient-color-2" />
        {/*<HomeBanner />*/}
        {/* <ProductListing />*/}
        {stats && stats.categories &&
    stats.categories.map((cat, index) => {
        return (<>
        <AnnoncesSlider 
            key={index} 
            type_de_bien={cat.type_de_bien} 
            titre={
                    cat.cat_name === "Appartement"
                        ? "Dernieres Annonces d'Appartements"
                        : "Dernieres Annonces de "+cat.pluriel_name 
            }
            style={index % 2 === 0 ? {background: 'var(--section-bg-1)'} : {background: "#fff"}}
        />
        </>)
    })
}


        
        {/*<Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
        <HomeTabs />*/}
        {/*<VideoV1 />
        <Category />
        <Testimonial />*/}
        {/*<BlogSlider customClass="section-subtitle-2"/>
        <CallToActionV1 />*/}
        <Footer />
    </div>
}

export default Accueil

