import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBath, faBed, faRulerCombined, faLayerGroup,faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import ImageCard from './components/ImageCard';
import Prix from './components/Prix';

let publicUrl = process.env.PUBLIC_URL + '/'
function CardAnnonce({ annonce, ...props }) {
	return (

		<div className="col-xl-4 col-sm-6 col-12">
			<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
			<div className="transaction-badge">
						{annonce.transaction.transaction_name}
					</div>
					<div className="bien-badge">
					{annonce.type_de_bien.cat_name}
					</div>
				<div className="product-img go-top">
					<Link to={`/annonce/${annonce.slug_title}`}>
						<ImageCard thumbnail={annonce.thumbnail} titre={annonce.titre} />
					</Link>
					<div className="prix-badge product-price">
					<Prix prix={annonce.prix} formatPrix={annonce.format_prix} unit_prix={annonce.unit_prix} />
					</div>
				</div>
				<div className="product-info">
					
					<h2 className="product-title go-top"><Link to={`/annonce/${annonce.slug_title}`}>{annonce.titre}</Link></h2>
					<div className="product-img-location go-top">
						<ul>
							<li>
								<i className="flaticon-pin" /> {annonce.localisation.commune_name_ascii}, {annonce.localisation.daira_name_ascii}, {annonce.localisation.wilaya_name_ascii}
							</li>
						</ul>
					</div>
					<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
						{annonce.pieces && <li><span><FontAwesomeIcon icon={faBed} /> {annonce.pieces} </span>
							
						</li>}
						{annonce.sdb && <li><span><FontAwesomeIcon icon={faBath} /> {annonce.sdb} </span>
							
						</li>}
						{annonce.superficie && <li><span><FontAwesomeIcon icon={faRulerCombined} /> {annonce.superficie} </span>
							m<sup>2</sup>
						</li>}
						<li><span>
							{annonce.etages !== null && (<>
								<FontAwesomeIcon icon={faLayerGroup} />
								
									{annonce.etages === 0
										? ' Rez de chaussée'
										: `Etage ${annonce.etages}`}
							</>)}

							</span></li>
					</ul>
				</div>
				{/*<div className="product-info-bottom">
					<div className="product-price">
						<span>$34,900<label>/Month</label></span>
					</div>
							</div>*/}
			</div>
		</div>

	)
}

export default CardAnnonce
