import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, Badge, Flex, Spin } from 'antd';
import { useSelector } from 'react-redux';
import CardAnnonce from '../Annonces/CardAnnonce';
import ListAnnonce from '../Annonces/ListAnnonce';

let publicUrl = process.env.PUBLIC_URL+'/'


function SearchResults(props) {

	const { stats, loading, rechercheAnnonces } = useSelector((state) => state.annonces);
    const [visible, setVisible] = useState(6); // Initial number of visible annonces

    const loadMore = () => {
        setVisible(prev => prev + 6); // Load 10 more annonces each time
    };

    useEffect(() => {
        
            console.log('rechercheeeeeeeeeeee',rechercheAnnonces)
        
    }, [rechercheAnnonces])
  return (
    <div>
		{loading && <Spin spinning={loading} fullscreen />}
      							<div className="tab-content">
							<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
								<div className="row">

									{/* ltn__product-item */}
                                    {rechercheAnnonces 
            ? rechercheAnnonces.slice(0, visible).map((annonce, index) => {
                return <CardAnnonce key={index} annonce={annonce} />
            })
            : "Aucune Annonces correspondant a votre recherche n'a été Trouvé!"
            }

								</div>
								</div>
							</div>
							<div className="tab-pane fade" id="liton_product_list">
								<div className="ltn__product-tab-content-inner ltn__product-list-view">
								<div className="row">

									{/* ltn__product-item */}
                                    {rechercheAnnonces && rechercheAnnonces.slice(0, visible).map((annonce, index) => {
                return <CardAnnonce key={index} annonce={annonce} />
            })
                                    }
									
									
								</div>
								</div>
							</div>
							</div>
							{
							rechercheAnnonces && visible < rechercheAnnonces.length && 
							<div className="ltn__pagination-area text-center">
							<div className="ltn__pagination">
                <button onClick={loadMore} type="button" className="load-more-btn">Voir Plus</button>
           </div></div> }
							{/*<div className="ltn__pagination-area text-center">
							<div className="ltn__pagination">
								<ul>
								<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
								<li><Link to="#">1</Link></li>
								<li className="active"><Link to="#">2</Link></li>
								<li><Link to="#">3</Link></li>
								<li><Link to="#">...</Link></li>
								<li><Link to="#">10</Link></li>
								<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
								</ul>
							</div>
                                </div>*/}
    </div>
  )
}

export default SearchResults
