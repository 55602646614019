import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Sidebar from '../shop-components/shop-sidebar';
import HomeBanner from '../section-components/HomeBanner';
import SearchForm from './Search/SearchForm';
import SearchResults from './Search/SearchResults';
let publicUrl = process.env.PUBLIC_URL + '/'
const SearchResultRightSidebar = ({form, handleResetForm}) => {


	return (
		<div>
			<br></br>
			<div className="ltn__product-area ltn__product-gutter">
				<div className="container">
					<div className="row">
						<div className="col-lg-12  mb-100">
							{/*<div className="ltn__shop-options">
								<ul className="justify-content-start">
									<li>
										<div className="ltn__grid-list-tab-menu ">
											<div className="nav">
												<a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large" /></a>
												<a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list" /></a>
											</div>
										</div>
									</li>
									<li className="d-none">
										<div className="showing-product-number text-right">
											<span>Showing 1–12 of 18 results</span>
										</div>
									</li>
									{/*<li>
								<div className="short-by text-center">
									<select className="nice-select">
									<option>Default Sorting</option>
									<option>Sort by popularity</option>
									<option>Sort by new arrivals</option>
									<option>Sort by price: low to high</option>
									<option>Sort by price: high to low</option>
									</select>
								</div> 
	</li>}
									{/*<li>
								<div className="short-by text-center">
									<select className="nice-select">
									<option>Per Page: 12</option>
									<option>Per Page: 20</option>
									<option>Per Page: 30</option>
									<option>Per Page: 50</option>
									<option>Per Page: 100</option>
									</select>
								</div> 
	</li>}
								</ul>
							</div>*/}
							<SearchResults />
						</div>

						{/*<div className="col-lg-4  mb-100">
							<aside className="sidebar ltn__shop-sidebar">
								<SearchForm form={form} handleResetForm={handleResetForm} />
							</aside>
						</div>*/}
						{/*<Sidebar />*/}
					</div>
				</div>
			</div>




		</div>
	)
}


export default SearchResultRightSidebar